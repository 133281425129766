import React, { useState, useRef, useEffect } from 'react';
import { Marquee } from './ui/marquee'; // Add this import
import image_recognition from '../assets/image_recognition.png';
import suggestions from '../assets/suggestions.png';
import tryon3 from '../assets/tryon3.gif';
import tryon4 from '../assets/tryon4.gif';
import online_shop from '../assets/online_shop.png';
import { useGoogleAnalytics } from '../useGoogleAnalytics';
import { supabase } from '../supabaseClient';
import Modal from './Modal';


const LandingPage = () => {
  /////////// Added from previous landingpage ///////////
  useGoogleAnalytics();

  // Sign up form state
  const [email, setEmail] = useState('')
  const [honeypot, setHoneypot] = useState('');
  const [status, setStatus] = useState('')
  const color = "#ffffff";

  useEffect(() => {
    const loadRecaptchaScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    loadRecaptchaScript()
    .then(() => console.log('reCAPTCHA script loaded successfully'))
    .catch(() => console.error('Error loading reCAPTCHA script'));

    return () => {
      // Remove the script when component unmounts
      const script = document.querySelector(`script[src^="https://www.google.com/recaptcha/api.js"]`);
      if (script) document.body.removeChild(script);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Check honeypot
      if (honeypot) {
        console.log('Potential bot detected');
        setStatus('Submission failed.');
        return;
      }

      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setStatus('Please enter a valid email address.');
        return;
      }

      setStatus('Verifying...');

      // Execute reCAPTCHA
      if (!window.grecaptcha) {
        console.error('reCAPTCHA not loaded');
        setStatus('An error occurred. Please try again later.');
        return;
      }

      const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: 'submit'});
      
      if (!token) {
        setStatus('reCAPTCHA verification failed. Please try again.');
        return;
      }

      // Get IP address
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();
      const ipAddress = ipData.ip;

      // Check if email already exists
      const { data: existingUsers, error: selectError } = await supabase
        .from('waitlist')
        .select('email')
        .eq('email', email);

      if (selectError) {
        console.error('Error checking for existing email:', selectError);
        setStatus('Error checking email. Please try again.');
        return;
      }

      console.log('Existing users query result:', existingUsers);

      if (existingUsers && existingUsers.length > 0) {
        setStatus('This email is already registered.');
        return;
      }

      // Check IP submission count
      const { count, error: countError } = await supabase
        .from('waitlist')
        .select('id', { count: 'exact' })
        .eq('ip_address', ipAddress);

      if (countError) {
        console.error('Error checking IP count:', countError);
        setStatus('An error occurred. Please try again.');
        return;
      }

      if (count >= 5) {
        setStatus('Maximum submissions reached');
        return;
      }

      // Submit to Supabase
      const { data: insertData, error: insertError } = await supabase
        .from('waitlist')
        .insert([
          { 
            email, 
            ip_address: ipAddress,
            user_agent: navigator.userAgent,
            captcha_token: token
          }
        ]);

      if (insertError) {
        console.error('Error signing up:', insertError);
        setStatus('Error signing up. Please try again.');
      } else {
        console.log('Signed up:', insertData);
        setStatus('Signed up successfully!');
        setEmail('');
      }
    } catch (error) {
      console.error('Submission error:', error);
      setStatus('An unexpected error occurred. Please try again.');
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleDownloadClick = () => {
    // Track the download button click
    if (window.gtag) {
      window.gtag('event', 'download_button_click', {
        'event_category': 'engagement',
        'event_label': 'Download App Button'
      });
    }
    
    // Open the modal
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // Scroll to the waitlist section
    const waitlistSection = document.getElementById('waitlist-section');
    if (waitlistSection) {
      waitlistSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  const featuresRef = useRef(null);

  return (
    <div className="font-sans">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Join the waitlist.{' '}
              <button onClick={closeModal} className="font-semibold text-indigo-600">
                <span aria-hidden="true" className="absolute inset-0" />
                Get 50% off first month <span aria-hidden="true">&rarr;</span>
              </button>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
              Discover, Style, Impress - All with AI.
            </h1>
            <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
              Upload your outfits and let AI suggest the perfect combinations based on your prompt, mood, occasion and style preferences.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <button
                onClick={handleDownloadClick}
                className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white font-bold py-3 px-6 rounded-full text-lg shadow-lg transform transition duration-300 hover:scale-105"
              >
                Download App
              </button>
            </div>            
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>

      {/* Features Section */}
      <section id="features" ref={featuresRef} className="bg-purple-100 py-20 shadow-inner">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-12 text-center">Key Features</h2>
          <div className="flex flex-col space-y-16">
            <div className="flex flex-col md:flex-row items-center gap-8 ">
              <div className="md:w-1/2">
                <img src={image_recognition} alt="Image Recognition" className="w-full h-auto rounded-lg " />
              </div>
              <div className="md:w-1/2">
                <h3 className="text-4xl font-semibold mb-4">Image Recognition</h3>
                <h3 className="text-2xl font-medium text-gray-600 mb-4">Simply upload your image and let AI process.</h3>
                <ul className="pl-5">
                  <li className="flex items-center font-medium text-lg text-pretty text-gray-600">
                    <span className="text-green-500 mr-2">✓</span>
                    No need to manually input your outfit details.
                  </li>
                  <li className="flex items-center font-medium text-lg text-pretty text-gray-600">
                    <span className="text-green-500 mr-2">✓</span>
                    Background is automatically removed.
                  </li>
                  <li className="flex items-center font-medium text-lg text-pretty text-gray-600">
                    <span className="text-green-500 mr-2">✓</span>
                    Change your outfit details to your desire with one click.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col md:flex-row-reverse items-center gap-8">
              <div className="md:w-1/2">
                <img src={suggestions} alt="image" className="w-full h-auto rounded-lg " />
              </div>
              <div className="md:w-1/2">
                <h3 className="text-4xl font-semibold mb-4">Get Smart Outfit Suggestions</h3>
                <p className="font-medium text-lg text-gray-600"> Get your <u>own clothes</u> paired according to your prompt</p>
                  <ul className="pl-5">
                    <li className="flex items-center font-medium text-lg text-pretty text-gray-600">
                      <span className="text-green-500 mr-2">✓</span>
                      Let AI stylist pair them into outfits for all occasions;
                    </li>
                    <li className="flex items-center font-medium text-lg text-pretty text-gray-600">
                      <span className="text-green-500 mr-2">✓</span>
                      Get inspired by new outfit ideas.
                    </li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Upcoming Features */}
      <section className="bg-white py-20 shadow-inner">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Upcoming Features</h2>
          <div className="flex flex-col space-y-16">
            <div className="flex flex-col md:flex-row items-center justify-between gap-8">
              <div className="md:w-1/2 mb-8 md:mb-0">
                <img src={tryon3} alt="Value Proposition" className="rounded-lg shadow-lg" />
              </div>
              <div className="md:w-1/2 md:pl-12">
                <h3 className="text-4xl font-semibold mb-4">Virtual Try-on</h3>
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span>
                    See outfits on models with OutfitAnyone's Virtual Try-on
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col md:flex-row-reverse items-center justify-between gap-8">
              <div className="md:w-1/2 mb-8 md:mb-0">
                <img src={tryon4} alt="Value Proposition" className="rounded-lg shadow-lg" />
              </div>
              <div className="md:w-1/2 md:pl-12">
                <h3 className="text-4xl font-semibold mb-4">Do not waste your valuable time trying on clothes </h3>
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span>
                    See how the outfits will look before you wear them.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-between gap-8">
              <div className="md:w-1/2 mb-8 md:mb-0 ">
                <img src={online_shop} alt="Online Shop" className="w-3/4 rounded-lg" />
              </div>
              <div className="md:w-1/2 md:pl-12">
                <h3 className="text-4xl font-semibold mb-4">Smart Shopping Assistant</h3>
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span>
                    Get shopping advice to match the clothes in your closet
                  </li>
                  <li className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span>
                    Do not waste your time searching for the perfect outfit.
                  </li>
                  <li className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span>
                    Do not waste money on clothes that do not match your style.
                  </li>
                  <li className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span>
                    Complete the missing pieces in your closet
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="bg-purple-100 py-20">
        <div className="container mx-auto px-4">
          {/* <h2 className="text-3xl font-bold mb-12 text-center">What Our Customers Say</h2> */}
          <Marquee className="py-4" pauseOnHover={true}>
            <TestimonialCard
              quote="I never wore these pants because I couldn't combine them."
              // author="Yasemin Nur"
            />
            <TestimonialCard
              quote="I can’t believe how much time I save. No more stressing about what to wear—my outfits are just ready for me."
              // author="Gökçin Vural"
            />
            <TestimonialCard
              quote="I've rediscovered so many pieces in my wardrobe thanks to these outfit suggestions. I feel like I have a whole new wardrobe without buying anything new!"
              // author="Deniz Demir"
            />
          </Marquee>
        </div>
      </section>
      
      {/* Waitlist Section */}
      <section id="waitlist-section"  className="bg-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-4 text-center">Join the Waitlist</h2>
          <p className="text-lg font-medium mb-4 text-center">Get <span className="text-2xl text-green-500 font-bold">50% discount</span> for first month with your email on the waitlist.</p>
          <form onSubmit={handleSubmit} className="max-w-md mx-auto">
            <div className="flex">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="border border-gray-400 flex-grow px-4 py-2 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-900"
                required
              />
              <input
                type="text"
                value={honeypot}
                onChange={(e) => setHoneypot(e.target.value)}
                style={{display: 'none'}}
                tabIndex="-1"
                autoComplete="off"
              />
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-r-lg transition duration-300"
              >
                Sign Up
              </button>
            </div>
          </form>
          {status && <p>{status}</p>}
        </div>
      </section>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 mb-3 sm:mb-4">Coming Soon!</h2>
        <p className="text-sm sm:text-base text-gray-600 mb-3 sm:mb-4">
          Mobile app is coming soon! Join the waitlist and be notified when it's available.
        </p>
        <p className="text-base sm:text-lg font-semibold text-purple-600 mb-4 sm:mb-6">
          Get 50% OFF your first month — join the waitlist today!
        </p>

        <div className="w-full">
          <button
            onClick={closeModal}
            className="w-full bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white font-bold py-2 px-4 rounded-full text-sm sm:text-base shadow-lg transform transition duration-300 hover:scale-105"
          >
            Join Waitlist & Get 50% OFF
          </button>
          <p className="text-xs sm:text-sm text-gray-500 mt-2 text-center">
            No credit card required
          </p>
        </div>
      </Modal>

    </div>
  );
};

const TestimonialCard = ({ quote, author }) => (
  <div className="bg-white p-6 rounded-lg shadow-md mx-2 w-80">
    <p className="text-lg italic mb-4">"{quote}"</p>
    {/* <p className="font-semibold">- {author}</p> */}
  </div>
);

export default LandingPage;
