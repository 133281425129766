import { useEffect } from 'react';

export const useGoogleAnalytics = () => {
  useEffect(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('config', 'G-81J2P7DWTJ', {
        page_path: window.location.pathname + window.location.search
      });
    }
  }, []);

  const trackEvent = (action, category, label, value) => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value
      });
    }
  };

  return { trackEvent };
};
