import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo_circular.png';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const Layout = ({ children, isLoggedIn, onLogout }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  // Define the handlePricingClick function
  const handlePricingClick = (e) => {
    e.preventDefault();
    const pricingSection = document.getElementById('pricing');
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleFeaturesClick = (e) => {
    e.preventDefault();
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleJoinWaitlistClick = (e) => {
    e.preventDefault();
    const waitlistSection = document.getElementById('waitlist-section');
    if (waitlistSection) {
      waitlistSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const navigation = [
    // { name: 'Product', href: '#' },
    { name: 'Features', href: '#features', onClick: handleFeaturesClick },
    // { name: 'Pricing', href: '#pricing', onClick: handlePricingClick },
    // { name: 'FAQ', href: '#' },
  ];

  const showNavigation = !isLoggedIn && location.pathname !== '/login';

  return (
    <div className="flex flex-col min-h-screen">
      <header className="shadow-sm h-20 absolute inset-x-0 top-0 z-50">
        <nav aria-label="Global" className="container mx-auto flex items-center justify-between p-6 lg:px-8 h-full">
          <div className="flex lg:flex-1">
            <Link to="/" className="flex items-center">
              <img src={logo} alt="Brand Logo" className="h-12 w-12 rounded-full object-cover" />
              <span className="ml-2 text-xl font-semibold text-gray-800">StyleGen AI</span>
            </Link>
          </div>
          {showNavigation && (
            <div className="flex lg:hidden">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(true)}
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
          )}
          
          {showNavigation && (
            <>
              <div className="hidden lg:flex lg:gap-x-12">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} onClick={item.onClick} className="text-sm font-semibold leading-6 text-gray-900">
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                <button onClick={handleJoinWaitlistClick} className="ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary hover:bg-primary/90 h-10 inline-flex items-center justify-center px-6 py-2 border-0 rounded-full text-sm font-medium text-white bg-gradient-to-l from-blue-500 to-purple-600 shadow-lg hover:from-purple-500 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Join Waitlist</button>
              </div>
            </>
          )}
        </nav>

        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">StyleGen AI</span>
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                {showNavigation && (
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={(e) => {
                          item.onClick && item.onClick(e);
                          setMobileMenuOpen(false);
                        }}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                )}
                <div className="py-6">
                  <button onClick={(e) => {
                    handleJoinWaitlistClick(e);
                    setMobileMenuOpen(false);
                  }} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Join Waitlist</button>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>

      <main className="flex-grow ">
        {children}
      </main>

      <footer className="bg-gray-900">
        <div className="container mx-auto px-4 py-4 text-center text-gray-600">
          © 2024 StyleGen AI - All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default Layout;
